import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import App from 'next/app';
import Head from 'next/head';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import acceptLanguage from 'accept-language';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/node';
import * as Theme from '../src/theme';
import '../public/static/css/main.css';
import messagesDe from '../src/translations/de.json';
import messagesEn from '../src/translations/en.json';
import messagesNl from '../src/translations/nl.json';
import messagesSk from '../src/translations/sk.json';
import messagesPl from '../src/translations/pl.json';
import messagesBg from '../src/translations/bg.json';
import messagesHu from '../src/translations/hu.json';
import messagesEl from '../src/translations/el.json';
import type { QuestionnaireSectionType } from '../components/questionnaire/QuestionnaireTypes';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    environment: process.env.SENTRY_ENV,
    dsn: process.env.SENTRY_DSN,
  });
}
const apiHost = `${process.env.API_HOST}/app`;
const mybiomaToken = process.env.MYBIOMA_TOKEN;

const locales = ['en', 'de', 'nl', 'sk', 'pl', 'bg', 'hu'];

const prioritySort = (a, b) => a.priority - b.priority;
const sortedQuestionnaire = questionnaire => {
  return {
    ...questionnaire,
    sections: [...questionnaire.sections]
      .sort(prioritySort)
      .map((section: QuestionnaireSectionType) => {
        return {
          ...section,
          questions: section.questions.sort(prioritySort).map(question => {
            return {
              ...question,
              answer_choices: question.answer_choices.sort(prioritySort),
            };
          }),
        };
      }),
  };
};

const getPartnerInfo = async token => {
  const partnerUrl = `${apiHost}/partners/public/${token}`;
  let partner;
  try {
    const partnerRes = await axios.get(partnerUrl);
    partner = partnerRes.data.success && partnerRes ? partnerRes.data.data : false;
  } catch (err) {
    partner = false;
  }
  return partner;
};

const getQuestionnaire = async (languageCode, token) => {
  const questionnaireUrl = `${apiHost}/questionnaires/public/${token}/${languageCode}`;
  let questionnaire;
  try {
    const questionnaireRes = await axios.get(questionnaireUrl);
    questionnaire =
      questionnaireRes.data.success && questionnaireRes
        ? sortedQuestionnaire(questionnaireRes.data.data)
        : false;
  } catch (err) {
    questionnaire = false;
  }
  return questionnaire;
};

export default class MyApp extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const { req, query } = ctx;
    acceptLanguage.languages(locales);
    // eslint-disable-next-line no-nested-ternary
    const locale = query.lang
      ? query.lang
      : req
      ? acceptLanguage.get(req.headers['accept-language'])
      : 'en';
    const { url } = req;
    const languageCode = locale;

    // Get questionnaire if activation or white-label page
    let questionnaire = false;
    let template = 'mybioma';

    const token = query.token ? query.token : mybiomaToken;
    if (!url.startsWith('/partner')) {
      const partner = await getPartnerInfo(token);
      template = partner.activation_template_name;
      questionnaire = await getQuestionnaire(languageCode, token);
    }
    const theme = Theme[template];

    return {
      pageProps: { ...pageProps, languageCode, questionnaire },
      locale,
      url,
      theme,
    };
  }

  render() {
    const messages = {
      de: messagesDe,
      en: messagesEn,
      nl: messagesNl,
      sk: messagesSk,
      pl: messagesPl,
      bg: messagesBg,
      hu: messagesHu,
      el: messagesEl,
    };
    const { Component, pageProps, url, err, locale, theme } = this.props;

    const message = messages[locale];

    const isWhiteLabel = url.startsWith('/whitelabel');
    const isPartner = url.startsWith('/partner');

    return (
      <IntlProvider messages={message} locale={locale} defaultLocale={locale}>
        <Head>
          <title>
            {isPartner
              ? message['Partner.Title']
              : isWhiteLabel
              ? message['WhiteLabel.Title']
              : message['Main.Title']}
          </title>
        </Head>
        <ThemeProvider theme={createMuiTheme(theme)}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </IntlProvider>
    );
  }
}
MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
